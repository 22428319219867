import { Injectable } from '@angular/core'

import { Action } from '@ngrx/store'
import { createEffect, Actions, ofType } from '@ngrx/effects'
import { Observable, of } from 'rxjs'
import { map, switchMap, mergeMap, catchError, exhaustMap } from 'rxjs/operators'

import * as ShopServiceActions from '@app/shop/store/actions/shopService.actions'
import * as ShopOrderActions from '@app/shop/store/actions/shopOrder.actions'

import { ShopServiceService } from '@app/shop/services/shopService.service'

import { ToastHelper } from '@app/core/services/toast.service'
import { Router } from '@angular/router'
import { OpenModal } from '@app/modals/store'
import { ShopOrderUpdate } from '../actions'
import { ShopShopOrder } from '@app/shop/models/shopOrder.model'

@Injectable()
export class ShopServiceEffects {
  getShopServices$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ShopServiceActions.GetShopOrderServices>(ShopServiceActions.ShopServiceActionTypes.GET_ORDERS_SERVICES),
    switchMap(action => {
      return this._ShopServiceService.get(action.payload).pipe(
        map(resp => {
          return new ShopServiceActions.GetShopOrderServicesSuccess(resp.data)
        }),
        catchError(error => of(new ShopServiceActions.GetShopOrderServicesError(error)))
      )
    })
  ))

  getCompletedShopServices$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ShopServiceActions.GetCompletedShopServices>(ShopServiceActions.ShopServiceActionTypes.GET_COMPLETED),
    switchMap(action => {
      return this._ShopServiceService.get(action.payload).pipe(
        map(resp => {
          return new ShopServiceActions.GetCompletedShopServicesSuccess(resp.data)
        }),
        catchError(error => of(new ShopServiceActions.GetCompletedShopServicesError(error)))
      )
    })
  ))

  selectOneShopService$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ShopServiceActions.GetOneShopService>(ShopServiceActions.ShopServiceActionTypes.GET_ONE),
    switchMap(action => {
      return this._ShopServiceService.getOne(action.payload).pipe(
        mergeMap(resp => {
          return [new ShopServiceActions.GetOneShopServiceSuccess(resp.data)]
        }),
        catchError(error => of(new ShopServiceActions.GetOneShopServiceError(error)))
      )
    })
  ))

  // updateShopService$: Observable<Action> = createEffect(() => this.actions$.pipe(
  //   ofType<ShopServiceActions.ShopServiceUpdate>(ShopServiceActions.ShopServiceActionTypes.UPDATE),
  //   switchMap(action => {
  //     return this._ShopServiceService.update(action.payload).pipe(
  //       map(resp => {
  //         this.notify.ok('ShopService updated successful!')
  //         return new ShopServiceActions.ShopServiceUpdateSuccess({ id: resp.data.service._id, changes: resp.data.service })
  //       }),
  //       catchError(error => of(new ShopServiceActions.ShopServiceUpdateError(error)))
  //     )
  //   })
  // ))
  updateShopService$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<ShopServiceActions.ShopServiceUpdate>(ShopServiceActions.ShopServiceActionTypes.UPDATE),
    switchMap(action => {
      return this._ShopServiceService.update(action.payload).pipe(
        switchMap(resp => {
          this.notify.ok('ShopService updated successfully!');
          return of();
        }),
        catchError(error => of(new ShopServiceActions.ShopServiceUpdateError(error)))
      )
    })
  ));

  createShopService$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ShopServiceActions.CreateShopService>(ShopServiceActions.ShopServiceActionTypes.CREATE),
    switchMap(action => {
      return this._ShopServiceService.save(action.payload.item).pipe(
        mergeMap(resp => {
          this.notify.ok('ShopService created successful!')
          return [
            new ShopServiceActions.CreateShopServiceSuccess(resp.data),
          ]
        }),
        catchError(error => of(new ShopServiceActions.CreateShopServiceError(error)))
      )
    })
  ))

  multiUpdateShopService$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ShopServiceActions.ShopServiceMultiUpdate>(ShopServiceActions.ShopServiceActionTypes.UPDATE_MULTI),
    switchMap(action => {
      return this._ShopServiceService.multiUpdate(action.payload).pipe(
        map(resp => {
          this.notify.ok('ShopServices updated successful!')
          return new ShopServiceActions.ShopServiceMultiUpdateSuccess(resp.data)
        }),
        catchError(error => of(new ShopServiceActions.ShopServiceMultiUpdateError(error)))
      )
    })
  ))

  deleteShopService$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ShopServiceActions.DeleteShopService>(ShopServiceActions.ShopServiceActionTypes.DELETE),
    switchMap(action => {
      return this._ShopServiceService.delete(action.payload).pipe(
        map(resp => {
          this.notify.ok('ShopService deleted successful!')
          return new ShopServiceActions.DeleteShopServiceSuccess(action.payload._id)
        }),
        catchError(error => of(new ShopServiceActions.DeleteShopServiceError(error)))
      )
    })
  ))
  deleteManyShopServices$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ShopServiceActions.DeleteManyShopServices>(ShopServiceActions.ShopServiceActionTypes.DELETE_MANY),
    switchMap(action => {
      return this._ShopServiceService.deleteMany(action.payload.deleteData).pipe(
        mergeMap(resp => {
          this.notify.ok(resp.message)
          return [
            new ShopServiceActions.DeleteManyShopServicesSuccess(resp)
          ]
        }),
        catchError(error => {
          this.notify.error(error)
          return of(new ShopServiceActions.DeleteManyShopServicesError(error))
        })
      )
    })
  ))

  getTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<typeof ShopServiceActions.GetServiceTypes.prototype>(ShopServiceActions.ShopServiceActionTypes.GET_TYPES),
      mergeMap(action => {
        return this._ShopServiceService.getTypes().pipe(
          switchMap((response) => [
            new ShopServiceActions.GetServiceTypesSuccess(response.data)
          ]),
          catchError(error => {
            this.notify.error(error)
            return of(new ShopServiceActions.GetServiceTypesError(error))
          })
        )
      })
    )
  })

  constructor(
    private actions$: Actions,
    private notify: ToastHelper,
    private _ShopServiceService: ShopServiceService,
    private router: Router
  ) { }
}
