import { Injectable } from '@angular/core'

import { Action } from '@ngrx/store'
import { createEffect, Actions, ofType } from '@ngrx/effects'
import { Observable, of } from 'rxjs'
import { map, switchMap, mergeMap, catchError, exhaustMap, tap } from 'rxjs/operators'

import * as ShopOrderActions from '@app/shop/store/actions/shopOrder.actions'

import { ShopOrderService } from '@app/shop/services/shopOrder.service'

import { ToastHelper } from '@app/core/services/toast.service'
import { Router } from '@angular/router'
import { CloseModal } from '@app/modals/store'


@Injectable()
export class ShopShopOrderEffects {
  getShopOrders$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ShopOrderActions.GetShopOrders>(ShopOrderActions.ShopOrderActionTypes.GET),
    switchMap(action => {
      return this._ShopOrderService.getAll(action.payload).pipe(
        map(resp => {
          return new ShopOrderActions.GetShopOrdersSuccess(resp.data)
        }),
        catchError(error => {
          this.notify.error(error.message || error)
          return of(new ShopOrderActions.GetShopOrdersError(error))})
      )
    })
  ))

  getCompletedShopOrders$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ShopOrderActions.GetCompletedShopOrders>(ShopOrderActions.ShopOrderActionTypes.GET_COMPLETED),
    switchMap(action => {
      return this._ShopOrderService.getAll(action.payload).pipe(
        map(resp => {
          return new ShopOrderActions.GetCompletedShopOrdersSuccess(resp.data)
        }),
        catchError(error => of(new ShopOrderActions.GetCompletedShopOrdersError(error)))
      )
    })
  ))

  selectOneShopOrder$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ShopOrderActions.GetOneShopOrder>(ShopOrderActions.ShopOrderActionTypes.GET_ONE),
    switchMap(action => {
      return this._ShopOrderService.getOne(action.payload).pipe(
        mergeMap(resp => {
          return [new ShopOrderActions.GetOneShopOrderSuccess(resp.data.orders[0])]
        }),
        catchError(error => {
          this.notify.error(error.message || error)
          return of(new ShopOrderActions.GetOneShopOrderError(error))})
      )
    })
  ))

  updateShopOrder$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ShopOrderActions.ShopOrderUpdate>(ShopOrderActions.ShopOrderActionTypes.UPDATE),
    switchMap(action => {
      return this._ShopOrderService.update(action.payload).pipe(
        map(resp => {
          this.notify.ok('ShopOrder updated successful!')
          return new ShopOrderActions.ShopOrderUpdateSuccess({ id: resp.data._id, changes: resp.data })
        }),
        catchError(error =>{ 
          this.notify.error(error.message || error)
          return of(new ShopOrderActions.DeleteShopOrderError(error))})
      )
    })
  ))

  createShopOrder$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ShopOrderActions.CreateShopOrder>(ShopOrderActions.ShopOrderActionTypes.CREATE),
    switchMap(action => {
      return this._ShopOrderService.save(action.payload).pipe(
        map(resp => {
          this.router.navigate(['shop/' + resp?.data?._id])
          this.notify.ok('ShopOrder created successful!')
          return new ShopOrderActions.CreateShopOrderSuccess({
            data: resp.data
          })
        }),
        catchError(error =>{ 
          this.notify.error(error.message || error)
          return of(new ShopOrderActions.CreateShopOrderError(error))})
      )
    })
  ))

  createShopOrderFromExpense$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ShopOrderActions.CreateShopOrderFromExpense>(ShopOrderActions.ShopOrderActionTypes.CREATE_FROM_EXPENSE),
    switchMap(action => {
      return this._ShopOrderService.createFromExpense(action.payload.expenseId).pipe(
        mergeMap(resp => {
          this.router.navigate(['shop/' + resp?.data?._id])
          this.notify.ok('ShopOrder created successful!')
          return [new CloseModal(), new ShopOrderActions.CreateShopOrderFromExpenseSuccess({
            data: resp.data
          })]
        }),
        catchError(error =>{ 
          this.notify.error(error.message || error)
          return of(new ShopOrderActions.CreateShopOrderFromExpenseError(error))})
      )
    })
  ))

  restoreManyShopOrders$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<typeof ShopOrderActions.RestoreManyShopOrders.prototype>(ShopOrderActions.ShopOrderActionTypes.RESTORE_MANY),
      mergeMap(action =>
        this._ShopOrderService.restoreMany(action.payload.ids).pipe(
          map(resp => {
            this.notify.ok(resp.message)
            return new ShopOrderActions.RestoreManyShopOrdersSuccess(resp)
          }),
          catchError(error => {
            this.notify.error(error)
            return of(new ShopOrderActions.RestoreManyShopOrdersError(error))
          })
        )
      )
    )
  })

  deleteShopOrder$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ShopOrderActions.DeleteShopOrder>(ShopOrderActions.ShopOrderActionTypes.DELETE),
    switchMap(action => {
      return this._ShopOrderService.delete(action.payload).pipe(
        map(resp => {
          this.router.navigate(['shop'])
          this.notify.ok('ShopOrder deleted successful!')
          return new ShopOrderActions.DeleteShopOrderSuccess(resp.data)
        }),
        catchError(error =>{ 
          this.notify.error(error.message || error)
          return of(new ShopOrderActions.DeleteShopOrderError(error))})
      )
    })
  ))

  getShopOrderBOL$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShopOrderActions.DeleteShopOrder>(ShopOrderActions.ShopOrderActionTypes.GENERATE_PDF),
      map(
        (action) =>
          new ShopOrderActions.ApiGeneratePDF({
            order: action.payload.order,
            query: action.payload.query,
          })
      )
    )
  })
  apiGeneratePDF$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<typeof ShopOrderActions.ApiGeneratePDF.prototype>(
        ShopOrderActions.ShopOrderActionTypes.API_GENERATE_PDF
      ),
      switchMap((action) => {
        // console.log('[Effect] Get Contract')
        return this._ShopOrderService
          .getShopOrderPDF(action.payload.order._id, action.payload.query)
          .pipe(
            mergeMap((resp) => [
              new ShopOrderActions.ApiGeneratePDFSuccess(action.payload),
              new ShopOrderActions.BrowserTriggerDownload({
                data: resp,
                name: `ShopOrder # ${action.payload.order.orderNumber}.pdf`,
              }),
            ]),
            catchError((error) =>
              of(
                new ShopOrderActions.ApiGeneratePDFError(error)
              )
            )
          )
      })
    )
  })
  downloadClaimFile$ = createEffect(() => this.actions$.pipe(
    ofType<ShopOrderActions.BrowserTriggerDownload>(
      ShopOrderActions.ShopOrderActionTypes.BROWSER_TRIGGER_DOWNLOAD
    ),
    tap(action => {
      const blob = new Blob([action.payload.data], {
        type: 'application/pdf'
      })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')

      a.href = url
      a.download = action.payload.name
      document.body.appendChild(a)
      a.click()
      setTimeout(function () {
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      }, 100)
    })
  ), { dispatch: false });


  constructor(
    private actions$: Actions,
    private notify: ToastHelper,
    private _ShopOrderService: ShopOrderService,
    private router: Router
  ) { }
}
