import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromFeature from '@app/shop/store/reducers';
import * as fromFilter from '@app/shop/store/reducers/shop-filter.reducers';
import { getUser } from '@app/users/store/selectors/auth.selectors';

let counter = 0;
// Filters State
export const selectFilterState = createSelector(
  fromFeature.selectShopFeatureState,
  (state: fromFeature.State) => state.filters
);

export const selectAllFilters = createSelector(
  selectFilterState,
  fromFilter.getFilters
);

export const selectAllFiltersWithUserCompanyShop = createSelector(
  getUser,
  selectAllFilters,
  (user, filter) => {
    if (filter && filter?.customerId === undefined && user?.company?._id) {
      filter.customerId = user.company._id;
      filter.customerName = user.company.name;
      counter++
    }else if(!counter && filter && !filter?.customerId && user?.company?._id){
      filter.customerId = user.company._id;
      filter.customerName = user.company.name;
      counter++
    }

    if (!user) {
      filter = {
        ...filter,
        authentication: true
      }
    }

    return filter;
  }
);
