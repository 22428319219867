import { createSelector } from '@ngrx/store'
import * as fromFeature from '@app/shop/store/reducers'
import * as fromShopServices from '@app/shop/store/reducers/shopService.reducers'

// Orders State

export const selectShopServicesState = createSelector(
  fromFeature.selectShopFeatureState,
  (state: fromFeature.State) => state.services
)

export const selectShopServicesTotal = createSelector(
  selectShopServicesState,
  fromShopServices.getTotalCount
)

export const selectShopServicesTypes = createSelector(
  selectShopServicesState,
  fromShopServices.getServiceTypes
)
export const getLoading = createSelector(
  selectShopServicesState,
  fromShopServices.getLoading
)

export const selectAllShopServices = createSelector(
  selectShopServicesState,
  fromShopServices.selectAllShopServices
)

export const selectShopServiceEntities = createSelector(
  selectShopServicesState,
  fromShopServices.selectShopServiceEntities
)


export const selectCurrentShopServiceId = createSelector(
  selectShopServicesState,
  fromShopServices.getSelectedId
)

export const selectShopService = createSelector(
  selectShopServiceEntities,
  selectCurrentShopServiceId,
  (entities, orderId) => entities[orderId]
)

export const selectShopServiceById = (serviceId) => createSelector(
  selectShopServiceEntities,
  (entities) => entities[serviceId]
)

export const selectCompletedShopServices = (unitId) => createSelector(
  selectAllShopServices,
  selectCurrentShopServiceId,
  (services, currentShopServiceId) => services.filter(e => (e._id != currentShopServiceId && e.status == 'COMPLETED' && e?.unit?._id == unitId))
);

