import { Injectable } from '@angular/core'
import { ApiService } from '@app/shared/services/api.service'
import { removeLocalVars } from '@app/shared/utils/arr.util'

@Injectable({
  providedIn: 'root'
})
export class ShopServiceService {
  path = '/shop-orders'

  constructor(private apiService: ApiService) { }

  _buildPath = query => `${this.path}/${query._orderId}/services`

  get = query => {
    return this.apiService.get(`${this._buildPath(query)}`, removeLocalVars({ ...query }))
  }

  getOne = query => this.apiService.get(`${this._buildPath(query)}/${query._id}`, removeLocalVars({ ...query }))

  save = (query) => this.apiService.post(`${this._buildPath(query)}`, removeLocalVars({ ...query }))

  update = query => this.apiService.patch(`${this._buildPath(query)}/${query._id}`, removeLocalVars({ ...query }))

  multiUpdate = query => this.apiService.put(`${this._buildPath(query)}/multi`, removeLocalVars({ ...query }))

  delete = query => this.apiService.delete(`${this._buildPath(query)}/${query._id}`,)

  getTypes = () => this.apiService.get(`${this.path}/services/types`)
  deleteMany = (query) => this.apiService.post(`${this._buildPath(query)}/deleteMany`, { ids: query.ids })

}
