import { Injectable } from '@angular/core'
import { ApiService } from '@app/shared/services/api.service'
import { removeLocalVars } from '@app/shared/utils/arr.util'

@Injectable({
  providedIn: 'root'
})
export class ShopOrderService {
  path = '/shop-orders'

  constructor(private apiService: ApiService) { }

  /* get = (_id: string, queryParams?: any) =>
    this.apiService.get(`${this.path}/${_id}`, queryParams) */

  getAll = query => this.apiService.get(this.path, removeLocalVars({ ...query }))

  getOne = query => this.apiService.get(`${this.path}/${query._id}`, removeLocalVars({ ...query }))

  save = (query) => this.apiService.post(`${this.path}`, removeLocalVars({ ...query }))

  createFromExpense = (expenseId) => this.apiService.post(`${this.path}/expense/${expenseId}`)

  update = query => this.apiService.patch(`${this.path}/${query.order._id}`, { order: removeLocalVars({ ...query.order }), populate: query.populate })

  delete = query => this.apiService.delete(`${this.path}/${query._id}`)

  getShopOrderPDF = (order_id, query) => this.apiService.getPdf(`${this.path}/${order_id}/pdf`, query)

  restoreMany = (ids: string[]) => this.apiService.post(this.path + `/restoreMany`, { ids })
}
